

















import UserTable from "@/components/avatar/UserTable.vue";
import { mapState } from "vuex";
import workspaceMixin from "@/mixins/workspace";
import { WorkspaceUserFilter } from "@/models/workspace";
import mixins from "vue-typed-mixins";
import {
  Role,
  OrganizationWorkspaceUser,
  User,
  OrganizationMutationContextUpdateOrganizationWorkspaceUserRolesArgs
} from "@/generated/graphql";
import { ActionTypes } from "@/store/action-types";
import store from "@/store/index";
import { UserInvite } from "@/models/user";

export default mixins(workspaceMixin).extend({
  components: {
    UserTable
  },
  data() {
    return {
      isFetchingUsers: false,
      eAdvocacy: localStorage.ea,
      changeable: localStorage.changeable
    };
  },
  computed: {
    ...mapState({
      users: state => state.workspace.currentWorkspaceUsers.list
    }),
    roles(): Role[] {
      return store.getters.getWorkspaceRoles as Role[];
    },
    hasNextPage(): boolean {
      return this.$storeTyped.state.workspace.currentWorkspaceUsers.hasNextPage;
    }
  },
  mounted() {
    if (localStorage.changeable) {
      this.changeable = localStorage.changeable;
      this.$root.$emit("changeable", localStorage.getItem("changeable"));
    }
  },
  methods: {
    onFilterChange(filter: WorkspaceUserFilter, loadMore = false) {
      this.isFetchingUsers = true;
      this.$storeTyped
        .dispatch(ActionTypes.FETCH_CURRENT_WORKSPACE_USERS, {
          filter,
          id: this.wsKey,
          loadMore
        })
        .finally(() => (this.isFetchingUsers = false));
    },
    async onEdit({
      user,
      roles
    }: {
      user: OrganizationWorkspaceUser;
      roles: string[];
    }) {
      const variables = {
        id: this.wsKey,
        input: roles,
        userKey: user.id
      } as OrganizationMutationContextUpdateOrganizationWorkspaceUserRolesArgs;
      await this.$storeTyped.dispatch(
        ActionTypes.UPDATE_ORGANIZATION_WORKSPACE_USER_ROLES,
        variables
      );
    },
    onInvite({ user, roles }: UserInvite) {
      this.$storeTyped.dispatch(ActionTypes.INVITE_WORKSPACE_USER, {
        email: user.email,
        roles
      });
    },
    onResend({ email, roles }: OrganizationWorkspaceUser) {
      this.$storeTyped.dispatch(ActionTypes.REINVITE_WORKSPACE_USER, {
        email,
        roles: (roles as Role[]).map(r => r.value)
      });
    },
    onDelete(user: User) {
      this.$storeTyped.dispatch(ActionTypes.DELETE_WORKSPACE_USER, user.id);
    }
  }
});
